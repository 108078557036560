import Token from '../utils/token';
import Network from '../utils/network';
import LocalStorageServices from './localStorage.services';
const InterceptorsServices = {
    setToken: async (axios) => {
        var config = {
            headers: {
                'Authorization': ''
            }
        }
        Network.check();
        await Token.validate();
        var data_token = LocalStorageServices.getDataToken();
        config.headers.Authorization = 'Bearer ' + data_token.access_token
        return config;
    }
}

export default InterceptorsServices;