import Crypto from '../utils/crypto'

const LocalStorageServices = {
    setCurrentUser: (data) => {
        let current_user = {
            id: data.id,
            name: data.name,
            avatar: data.avatar,
            email: data.email,
            permissions: data.permissions !== null ? data.permissions : []
        }
        current_user = Crypto.encrypt(current_user);
        localStorage.setItem('currentUser', current_user);
    },
    /**
     * 
     * @returns 
     */
    getCurrentUser: () => {
        let current_user = Crypto.decrypted(localStorage.getItem('currentUser'));
        return current_user;
    },
    deleteCurrentUser: () => {
        localStorage.removeItem('currentUser');
    },
    /**
     * Funcion para almacenar los datos de la sesion
     * @param {object} data objeto que devuelve la api
     */
    setDataToken: (data) => {
        let create = new Date().getTime();
        let data_token = {
            access_token: data.access_token,
            refresh_token: data.refresh_token,
            expira: data.expires_in,
            create: create
        }
        data_token = Crypto.encrypt(data_token);
        localStorage.setItem('dataToken', data_token);
    },
    /**
     * Devuelve todos los datos del token
     * @returns {object}
     */
    getDataToken: () => {
        let token = Crypto.decrypted(localStorage.getItem('dataToken'));
        return token;
    },
    /**
     * Eliminar el token
     */
    deleteDataToken: () => {
        localStorage.removeItem('dataToken');
    },
    /**
    * Eliminar todos los datos del storage
    */
    deleteAllData: () => {
        localStorage.clear();
    },
}

export default LocalStorageServices;