import API from './baseApi';
import InterceptorsServices from '../interceptors.services';

const AuthServices = {
  /**
   * Funcion para hacer login
   * @param {object} data Dato de logueo
   * @returns {object}
   */
  login: async (data) => {
    const response = await API.post('/login', data);
    return response;
  },
  /**
   * Funcion para validar PIN
   * @param {object} data Form de PIN
   * @returns {object}
   */
  validatePin: async (data) => {
    const response = await API.post('/verify-pin', data);
    return response;
  },

  /**
   * Funcion para resetear el passsword
   * @param {object} data Form de email
   * @returns {object}
   */
  resetPassword: async (data) => {
    const response = await API.post('/reset-password', data);
    return response;
  },
  /**
   * Funcion para refrescar el token
   * @param {object} data Refresh token
   * @returns {object}
   */
  refreshToken: async (data) => {
    const response = await API.post('/refresh', data);
    return response;
  },
  /**
   * Funcionan para cerrar sesion
   * @returns 
   */
  logout: async (data) => {
    var config = await InterceptorsServices.setToken();
    const response = await API.post('/logout', data, config);
    return response;
  },
};
export default AuthServices;
