
import CryptoJS from 'crypto-js';
import { GENERAL } from '../env-config';
const Crypto = {
    encrypt: (data) => {
        let data_encrypt = CryptoJS.AES.encrypt(JSON.stringify(data), GENERAL.KEY_CRIPTO).toString();
        return data_encrypt;
    },
    decrypted: (data) => {
        try {
            let bytes = CryptoJS.AES.decrypt(data, GENERAL.KEY_CRIPTO);
            let decrypted_data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return decrypted_data;
        } catch (e) {
            return false;
        }
    },
}

export default Crypto;