import LocalStorageServices from '../services/localStorage.services';
import AuthServices from '../services/apiLogin/auth.services';
import notifications from '@iso/components/Feedback/Notification';
const Token = {
    /**
     * Valida cuanto tiempo de vida le queda al access token y lo refresca
     * @returns
     */
    validate: async () => {
        var data_token = LocalStorageServices.getDataToken();
        const time = data_token.create;
        const now = new Date().getTime();
        const expire = typeof data_token.expira !== 'undefined' ? data_token.expira : 0;
        var sec_diff = (now - time) / 1000;
        const remainingTime = expire - sec_diff;
        if (remainingTime < 60) {
            // send valid token
            let data = {
                refresh_token: data_token.refresh_token,
            }
            await AuthServices.refreshToken(data)
                .then((response) => {
                    if (typeof response.data.access_token !== 'undefined') {
                        data_token.access_token = response.data.access_token;
                        data_token.expires_in = response.data.expires_in;
                        LocalStorageServices.setDataToken(data_token);
                    } else {
                        LocalStorageServices.deleteAllData();
                        window.location.href = '/';
                    }
                })
                .catch((error) => {
                    LocalStorageServices.deleteAllData();
                    window.location.href = '/';
                })
        }
        return;
    },
    /**
     * 
     * @param {*} response 
     * @param {*} message  para mostrar si no es error 401 ni error 403
     * @param {*} description  para mostrar si no es error 401 ni error 403
     * @returns 
     */
    validateStatusConnection: (response, message, description) => {
        if (typeof response === 'undefined') {
            return;
        }

        if (response.status === 401) {
            notifications['error']({
                message: 'Acceso Denegado',
                description: 'Se ha cerrado la sesión debido a que ha caducado o haz iniciado sesión en otro dispositivo',
            });
            LocalStorageServices.deleteAllData();
            window.location.href = '/';
            return;
        }

        if (response.status === 403) {
            notifications['error']({
                message: 'Permisos insuficientes',
                description: 'No tienes los permisos para realizar esta acción',
            });
            return;
        }

        if (response.status !== 403 && response.status !== 401) {
            notifications['error']({
                message: message,
                description: description,
            });
            return;
        }
        return;
    }
}

export default Token;